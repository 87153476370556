import axios from 'axios'
import Vuex from 'vuex'

let store=new Vuex.Store({
    state: {
        workers: [],
        tasks: []
    },
    mutations: {
        SET_WORKERS_TO_STATE: (state, workers)=>{
            state.workers=workers
        },
        SET_TASKS_TO_STATE: (state,tasks)=>{
            state.tasks=tasks
        }
    },
    actions: {
        GET_WORKERS({commit}) {
            return axios('https://crm.vbi.ru/dev/tasksbalance.php',{
                method: "GET",
                params: {
                    key: '32DevBalanceTasksMD5SHA123PwdKey'
                }
            })
            .then(resp=>{
                if(resp.data.res) {
                    commit('SET_WORKERS_TO_STATE',resp.data.data)
                }
                return(resp)
            })
            .catch(error=>{
                return error
            })
        },
        GET_TASKS({commit}) {
            let month=new Date().getMonth();
            return axios('https://crm.vbi.ru/dev/devgetallmonthtasks.php',{
                method: "GET",
                params: {
                    key: '32DevBalanceTasksMD5SHA123PwdKey',
                    month: month+1
                }
            })
            .then(resp=>{
                if(resp.data.res) {
                    commit('SET_TASKS_TO_STATE',resp.data.data)
                }
                return(resp)
            })
            .catch(error=>{
                return error
            })
        }
    },
    getters: {
        WORKERS(state) {
            return state.workers
        },
        TASKS(state) {
            return state.tasks
        }
    }
})

export default store;
