import { createRouter, createWebHistory } from 'vue-router'
import authPage from '@/components/auth/authPage'
import workersList from '@/components/workersList/workersList'


const routes = [
  {
    path: '/',
    name: 'auth',
    component: authPage
  },
  {
    path: '/workersList',
    name: 'workersList',
    component: workersList
  }
] 

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
