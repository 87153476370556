<template>
  <div class="authPage">
    <auth-Form/>
  </div>
</template>

<script>
import authForm from "./authForm.vue"

export default {
    name:"authPage",
    components: {
      authForm
    },
    props:{},
    data() {
        return {
            text: "text"
        }
    },
    computed: {
       
    }
}
</script>

<style>
 .authPage {
    display:flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    
 }
</style>