<template>
  <div class="App">
    <router-view />
  </div>
</template>

<script>
export default {
  
  data: function(){
    return {
     
    }
  },
  name: 'App', 
  
  mounted() {
    if(localStorage.isAuth&&localStorage.isAuth==='true') {
        console.log(localStorage.isAuth + ' перенаправляем')
        this.$router.push('/workersList')
    } else {
        console.log(localStorage.isAuth + ' на авторизацию')
        this.$router.push('')
    }      
  },
}
</script>

<style>
  *{margin: 0;}
  .App {
    background-color: #1f1f1f;
        margin: 0;
  }
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fafafa;
    text-align: left;
    background-color: #1f1f1f;
  }
   
</style>
