<template>
  <div class="workersList">
    <div class="exit_btn" @click="logOut">
      <p>
        LogOut
        <fa icon="fa-solid fa-right-from-bracket" />
      </p>
    </div>
    <div class="all_container">
    <div class="allStat">
      <img src="@/img/lenin.svg" class="allStat__img" />

      <div v-if="TASKS.started.length" class="allStat__month">
        <h2>Этот месяц:</h2>
        <span>
          <fa icon="fa-solid fa-right-to-bracket" />
          Новые задачи: {{TASKS.started.length}}
        </span>
        <span>
          <fa icon="fa-solid fa-flag-checkered" />
          Закрытые задачи: {{TASKS.closed.length}}
        </span>
        <span>
          <fa icon="fa-solid fa-kiwi-bird" />
          Процент выполнения: {{Math.floor(TASKS.closed.length/TASKS.started.length*100)+'%'}}</span>
      </div>
      <ul class="allStat__detail">
        <li class="allStat__listLtem">
          <h2>Всего: {{ tasksCount }}</h2>
        </li>
        <li class="allStat__listLtem">
          <span class="allStat__icon _red">
            <fa icon="fa-solid fa-circle-exclamation" />
          </span>
          Просрочено: {{ deadlineCount }}
        </li>
        <li class="allStat__listLtem">
          <span class="allStat__icon">
            <fa icon="fa-solid fa-person-digging" />
          </span>
          Выполняется: {{ inProgressCount }}
        </li>
        <li class="allStat__listLtem">
          <span class="allStat__icon _orange">
            <fa icon="fa-solid fa-frog" />
          </span>
          Ждет выполнения :{{ waitExecuteCount }}
        </li>

        <li class="allStat__listLtem">
          <span class="allStat__icon _blue">
            <fa icon="fa-solid fa-hourglass-start" />
          </span>
          Ждет контроля: {{ waitControlCount }}
        </li>
        <li class="allStat__listLtem">
          <span class="allStat__icon _blue">
            <fa icon="fa-solid fa-box-archive" />
          </span>
          Отложено: {{ asideCount }}
        </li>
      </ul>

      <ul class="workers">
        <li
          v-for="worker in WORKERS"
          v-bind:key="worker.id"
          class="workers__item"
        >
          <div class="workers__ava-container">
            <img :src="worker.photo" class="workers__ava" />
          </div>
          <div class="workers__allTask">
            <h4>всего {{ worker.tasksCount }}:</h4>
          </div>

          <div class="workers__item_stat _red">
            <fa icon="fa-solid fa-circle-exclamation" />
            {{ worker.deadlineCount }}
          </div>
          <div class="workers__item_stat">
            <fa icon="fa-solid fa-person-digging" />
            {{ worker.inProgress }}
          </div>
          <div class="workers__item_stat _orange">
            <fa icon="fa-solid fa-frog" />
            {{ worker.waitExecute }}
          </div>
          <div class="workers__item_stat _blue">
            <fa icon="fa-solid fa-hourglass-start" />
            {{ worker.waitControlCount }}
          </div>
          <div class="workers__item_stat _blue">
            <fa icon="fa-solid fa-box-archive" />
            {{ worker.setAsideCount }}
          </div>
        </li>
      </ul>
    </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      waitExecuteCount: 0,
      inProgressCount: 0,
      waitControlCount: 0,
      asideCount: 0,
      tasksCount: 0,
      deadlineCount: 0,

    };
  },
  methods: {
    ...mapActions(["GET_WORKERS","GET_TASKS"]),
    logOut() {
      localStorage.isAuth = "false";
      this.$router.push("/");
    },
    updStatus() {
      setInterval(() => {
        this.GET_WORKERS();
        console.log("updated")
      }, 30000);
    },
  },
  mounted() {
    this.GET_WORKERS()
    this.GET_TASKS()
    this.updStatus()
    console.log('sdf')
  },
  watch: {
    WORKERS: function () {
      this.waitExecuteCount = 0;
      this.inProgressCount = 0;
      this.waitControlCount = 0;
      this.asideCount = 0;
      this.tasksCount = 0;
      this.deadlineCount = 0;
      for (const workerID in this.WORKERS) {
        //this.workers.push(this.WORKERS)
        this.waitExecuteCount += this.WORKERS[workerID].waitExecute;
        this.inProgressCount += this.WORKERS[workerID].inProgress;
        this.waitControlCount += this.WORKERS[workerID].waitControlCount;
        this.asideCount += this.WORKERS[workerID].setAsideCount;
        this.tasksCount += this.WORKERS[workerID].tasksCount;
        this.deadlineCount += this.WORKERS[workerID].deadlineCount;
      }
    },
    TASKS: function() {
      this.tasks=this.TASKS
    }
  },
  computed: {
    ...mapGetters(["WORKERS","TASKS"]),
  },
};
</script>

<style scoped>
.all_container {

  display: flex;
  justify-content: center;
  align-items: center;
}
.allStat {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #333333; */
  width: fit-content;
  border: 1px solid #444444;
  border-radius: 5px;
  padding: 3px 15px;
}

.allStat__img {
  width: 133px;
}
.allStat__month{
  display: flex;
  flex-direction: column;
}
.allStat__detail {
  list-style: none;
}
.allStat__listLtem {
  font-size: 16px;
}
.allStat__icon {
  font-size: 20px;
}

._red {
  color: #ec5941;
}
._green {
  color: #23e896;
}
._blue {
  color: #428fdc;
}
._dark-blue {
  color: #4c6ffe;
}
._orange {
  color: #ffcb3d;
}
.exit_btn {
  display: flex;
  justify-content: flex-end;
  width: 100px;
  margin: 0 0 0 auto;
  padding: 10px;
  opacity: 0.7;
  transition: opacity ease-in-out 0.5s;
  z-index: 30;
}
.exit_btn:hover {
  cursor: pointer;
  opacity: 1;
}
/* circle*/
.rc {
  border-radius: 50%;
  width: 105px;
  height: 105px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rc-2 {
  border-radius: 50%;
  width: 75px;
  height: 75px;
  background: #1f1f1f;
  font-size: 20px;
  font-family: sans-serif;
  font-weight: bold;
  color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
}
.workers {
  list-style: none;
}
.workers__item {
  display: flex;
  align-items: center;
}
.workers__ava-container {
  overflow: hidden;
  margin: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #88c5f7;
}
.workers__ava {
  width: 40px;
  background-size: cover;
}
.workers__item_stat {
  font-size: 15px;
  margin-left: 5px;
}
.workers__allTask {
  min-width: 25px;
}
</style>
