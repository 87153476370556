/* Set up using Vue 3 */
import { createApp } from 'vue'
import App from './App.vue'
import store from './store/store'
import router from './router'


//иконки
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserAstronaut, faRightFromBracket, faCrow, faCircleExclamation,
        faKiwiBird, faUser, faArrowPointer,faCoins,
        faGaugeHigh, faChartLine, faPercent,
        faUsersViewfinder, faCubes, faPencil, faList, faPenToSquare, faPauseCircle, faCircleInfo, faChevronLeft, faPersonDigging, faFrog, faHourglassStart, faBoxArchive, faFlagCheckered, faRightToBracket
        } from '@fortawesome/free-solid-svg-icons'
import { faEarlybirds, faYandex} from '@fortawesome/free-brands-svg-icons'
library.add(faCubes,faUsersViewfinder, faHourglassStart, faBoxArchive,
    faPersonDigging, faFrog,faCircleExclamation,faFlagCheckered,
    faPercent,faChartLine,faGaugeHigh, faUserAstronaut,
    faRightFromBracket, faRightToBracket, faEarlybirds,faCrow,faKiwiBird,faUser,
    faYandex,faArrowPointer,faCoins,faPencil,faList,faPenToSquare,faPauseCircle,faCircleInfo, faChevronLeft)


createApp(App).use(router).
use(store)
.component('fa', FontAwesomeIcon)
.mount('#app')
